<template>
  <div class="exception">
    <div class="exception-img">
      <div class="exception-img-element" />
    </div>
    <div class="exception-content">
      <div class="exception-content-error">404</div>
      <div class="exception-content-desc">抱歉，您访问的页面不存在</div>
      <el-button type="primary" @click="goHome">返回首页</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error404',
  methods: {
    goHome() {
      this.$router.push({
        name: 'home'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.exception {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  &-img {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-element {
      width: 100%;
      max-width: 430px;
      height: 360px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      background-image: url('../../assets/icon-404.svg');
    }
  }

  &-content {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &-error {
      color: #515a6e;
      font-weight: 600;
      font-size: 72px;
      line-height: 72px;
      margin-bottom: 24px;
    }

    &-desc {
      margin-bottom: 16px;
      color: #808695;
      font-size: 20px;
      line-height: 28px;
    }
  }
}
</style>
