<template>
  <div class="rangeInput">
    <el-input-number v-model="val1" v-bind="cfg"></el-input-number>
    <span> - </span>
    <el-input-number v-model="val2" v-bind="cfg"></el-input-number>
  </div>
</template>

<script>
export default {
  props: {
    cfg: {
      type: Object,
      default: () => ({})
    },
    rangeVal: {
      type: Array
    }
  },
  computed: {
    val1: {
      get() {
        return this.rangeVal[0] || undefined
      },
      set(e) {
        this.$emit('otherchange', [e, this.val2 || undefined])
      }
    },
    val2: {
      get() {
        return this.rangeVal[1] || undefined
      },
      set(e) {
        this.$emit('otherchange', [this.val1 || undefined, e])
      }
    }
  }
}
</script>

<style></style>
